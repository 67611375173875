import {Avatar, Typography} from '@mui/material';
import {Box} from '@mui/system';
import theme from '../../styles/theme';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BottomBar from './BottomBar';
import {useNavigate, useParams} from 'react-router-dom';
import {CONSTANTS} from '../../constants';
import Modal from './Modal';
import {useState} from 'react';
import useLeaderStore from '../../store/leaderStore';
import moment from 'moment';
import MarkAttendance from '../../pages/MarkAttendance';

const UserContainer = ({children, addText, addText2, headingText, subHeadingText, filePath, isGroup, groupData, isShow = true, isShow2 = true, headerText,  showCheckbox=false, onClick, isDisciple, fetchGroupDiscipleId="", meetingId="", currentMeetingDate="",previousMeetingDate="",nextMeetingDate="",isSkipWeek }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [addDisciple, setAddDisciple] = useState(false);
  const [isReadingPlan, setIsReadingPlan] = useState(false);
  const {meetings} = useLeaderStore(state => state);

  const groupNavigationHandler = (isDiscipleOpened = false) => {
    if (groupData) {
      if (groupData?.group_meeting?.group_week_meeting_added) {
        navigate(`/create-group?group=${params?.id}&page=${5}`);
      } else {
        setIsReadingPlan(true);
        return;
      }
      if (isDiscipleOpened) {
        if (groupData?.disciple?.length === 0) {
          setAddDisciple(true);
          return;
        }
      }
    } else {
      navigate(`/create-group?isdisciple=true&group=${params.id}`);
    }
  };
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Modal
        open={isReadingPlan}
        setOpen={setIsReadingPlan}
        title="Invite Disciple"
        buttons={[
          {name: 'Cancel', onClick: () => setIsReadingPlan(false)},
          {
            name: 'Add Reading',
            onClick: () => {
              if (moment(meetings?.starting_time).isBefore() || groupData?.disciple?.length > 0) {
                navigate(`/leader/reading-plans?group_id=${groupData?.id}`);
              } else {
                navigate(`/leader/meetings?group_id=${groupData?.id}`);
              }
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>Please add reading plan prior to invite disciples.</Typography>
      </Modal>
      <Modal
        open={addDisciple}
        setOpen={setAddDisciple}
        title="Invite Disciple"
        buttons={[
          {name: 'No', onClick: () => setAddDisciple(false)},
          {
            name: 'Yes',
            onClick: () => groupNavigationHandler()
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>After disciple invited there will be no change in Reading Plan. Do want to continue?</Typography>
      </Modal>
      <Box sx={{backgroundColor: '#9e0001', zIndex: 99}} position="sticky" top={0} left={0} right={0} p={theme.spacing(2)} display={'flex'} alignItems={'center'} justifyContent={'space-between'} borderBottom={2} borderColor="black">
        <Box display={'flex'} alignItems={'center'}>
          <Avatar sx={{width: 56, height: 56}} alt={String(groupData?.name).toUpperCase()} src={filePath} />
          <Box textAlign={'left'} paddingInline={theme.spacing(2)}>
            <Typography variant="h6" fontWeight={'600'}>
              {headingText}
            </Typography>
            <Typography variant="body1">{subHeadingText}</Typography>
            {headerText && (
              <Typography variant="body1" fontSize={12}>
                {isDisciple ? headerText : `Organization: ${headerText.length > CONSTANTS.ORGANIZATION_LENGTH ? `${headerText}...` : headerText}`}
              </Typography>
            )}
            {/* Conditionally render checkbox if Date is today or in the past */}
            {/* {showCheckbox && meetingId && (
            
             <MarkAttendance fetchGroupDiscipleId={fetchGroupDiscipleId} meetingId={meetingId} currentMeetingDate={currentMeetingDate} previousMeetingDate={previousMeetingDate} nextMeetingDate={nextMeetingDate} isSkipWeek={isSkipWeek}/>
            )} */}
          </Box>
        </Box>
        {isGroup && (
          <span className="cursor-pointer" onClick={() => navigate(`/edit-group/${groupData?.id}`)}>
            <BorderColorIcon sx={{fontSize: '22px'}} color="error" />
          </span>
        )}
      </Box>
      <Box sx={{paddingBottom: '20px'}}>{children}</Box>
      <BottomBar
        buttonInputs={[
          {text: 'Home', onClick: () => navigate('/leader')},
          {
            text: addText,
            onClick: () => (isGroup ? groupNavigationHandler(true) : onClick()),
            isShow: isShow
          },
          {
            text: addText2,
            onClick: () => {
              if (moment(meetings?.starting_time).isBefore() || groupData?.disciple?.length > 0) {
                navigate(`/leader/reading-plans?group_id=${groupData?.id}`);
              } else {
                navigate(`/leader/meetings?group_id=${groupData?.id}`);
              }
            },
            isShow: isShow2
          },
          {text: 'Settings', onClick: () => {}}
        ]}
      />
    </Box>
  );
};

export default UserContainer;
