import React, {useState} from 'react';
import {Box, Grid, Alert, useMediaQuery} from '@mui/material';
import TextInput from '../../components/common/TextInput';
import FormWrapper from '../../components/common/StyledFormWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AdminStructure from './common/AdminStructure';
import PasswordChecklist from 'react-password-checklist';
import {useNavigate} from 'react-router-dom';
import {changePasswordApi} from '../../api';
import toast from 'react-hot-toast';

function AdminChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const changePasswordHandler = async () => {
    if (!isValidPassword) {
      setError('Invalid password!');
      return;
    }
    const data = {
      currentPassword: currentPassword,
      newPassword: password
    };
    try {
      setLoading(true);
      const response = await changePasswordApi(data);
      if (response?.data?.status === 200) {
        navigate('/admin-dashboard/groups');
      } else {
        setError('Failed to change password!');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleUpdate = e => {
    e.preventDefault();
    if (!currentPassword || !password || !confirmPassword) {
      setError('All fields are required!');
      return;
    }
    changePasswordHandler();
  };
  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflowY: isSmallScreen ? 'auto' : 'hidden'
        }}
      >
        <AdminStructure>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
            sx={{
              height: '80vh',
              overflowY: 'auto',
              paddingX: {
                xs: '20px',
                sm: '30px'
                // md: "30px",
              }
            }}
          >
            <>
              <FormWrapper buttonInputs={[{text: 'Update', onClick: handleUpdate}]} headingText="Change Password" headingTextColor="black" loading={loading}>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <TextInput
                      autoComplete="off"
                      label="Current Password"
                      labelColor="black"
                      value={currentPassword}
                      placeholder="Current Password"
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setCurrentPassword(e.target.value);
                        setError(null);
                      }}
                      type={showCurrentPassword ? 'text' : 'password'}
                      labelVariant="body2"
                      colorBlack={'text-black'}
                      sx={{
                        mb: 1,
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid #ccc',
                        borderRadius: '15px',
                        padding: '3px',
                        maxWidth: '98%',
                        '& .MuiInputBase-input': {
                          color: 'black !important'
                        },
                        '& .MuiInputBase-input:focus': {
                          color: 'black !important'
                        }
                      }}
                    />
                    {showCurrentPassword ? <VisibilityIcon onClick={() => setShowCurrentPassword(false)} className="eye_icon_change" /> : <VisibilityOffIcon onClick={() => setShowCurrentPassword(true)} className="eye_icon_change" />}
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <TextInput
                      autoComplete="off"
                      label="New Password"
                      labelColor="black"
                      value={password}
                      placeholder="New Password"
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setPassword(e.target.value);
                        setError(null);
                      }}
                      type={showPassword ? 'text' : 'password'}
                      labelVariant="body2"
                      colorBlack={'text-black'}
                      sx={{
                        mb: 1,
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid #ccc',
                        borderRadius: '15px',
                        padding: '3px',
                        maxWidth: '98%',
                        '& .MuiInputBase-input': {
                          color: 'black !important'
                        },
                        '& .MuiInputBase-input:focus': {
                          color: 'black !important'
                        }
                      }}
                    />
                    {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_icon_change" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_icon_change" />}
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <TextInput
                      autoComplete="off"
                      label="Confirm New Password"
                      labelColor="black"
                      value={confirmPassword}
                      placeholder="Confirm New Password"
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                        setError(null);
                      }}
                      type={showConfirmPassword ? 'text' : 'password'}
                      labelVariant="body2"
                      colorBlack={'text-black'}
                      sx={{
                        mb: 1,
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid #ccc',
                        borderRadius: '15px',
                        padding: '3px',
                        maxWidth: '98%',
                        '& .MuiInputBase-input': {
                          color: 'black !important'
                        },
                        '& .MuiInputBase-input:focus': {
                          color: 'black !important'
                        }
                      }}
                    />
                    {showConfirmPassword ? <VisibilityIcon onClick={() => setShowConfirmPassword(false)} className="eye_icon_change" /> : <VisibilityOffIcon onClick={() => setShowConfirmPassword(true)} className="eye_icon_change" />}
                  </div>
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                    minLength={8}
                    validColor="black"
                    value={password}
                    valueAgain={confirmPassword}
                    style={{textAlign: 'start'}}
                    messages={{
                      minLength: 'Should be mininum 8 characters.',
                      specialChar: 'Should contain special character.',
                      number: 'Should contain number.',
                      capital: 'Should contain uppercase letter.',
                      lowercase: 'Should contain lowercase letter.',
                      match: 'Password and confirm password should be same.'
                    }}
                    onChange={isValid => setIsValidPassword(isValid)}
                  />
                </Grid>
                <Grid item xs={12} sx={{height: '55px'}}>
                  {error && (
                    <Alert
                      severity="error"
                      sx={{
                        '& .MuiAlert-message': {color: '#000000'},
                        '& .MuiAlert-icon': {color: '#000000'}
                      }}
                    >
                      {error}
                    </Alert>
                  )}
                </Grid>
              </FormWrapper>
            </>
          </Box>
        </AdminStructure>
      </div>
    </>
  );
}
export default AdminChangePassword;
