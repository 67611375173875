import {useEffect, useState} from 'react';
import {Alert, Box, Grid, Typography} from '@mui/material';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {postUpdateBibleReadingPlanApi, sendMeetingInvite, updateMeetingApi} from '../api/index';
import HeaderContainer from '../components/common/HeaderBox';
import FormWrapper from '../components/common/StyledFormWrapper';
import SelectInput from '../components/common/SelectInput';
import TextInput from '../components/common/TextInput';
import {CONSTANTS} from '../constants/index';
import DatePicker from '../components/common/DatePicker';
import BottomBar from '../components/common/BottomBar';
import useLeaderStore from '../store/leaderStore';
import {allFieldsHaveValue, checkObjectAnyKeyHasValue} from '../utils';
import moment from 'moment';
import TimePickerComponent from '../components/common/TimePicker';
import Modal from '../components/common/Modal';

const StartMeeting = () => {
  const {meetings, setMeetings, group, editingMeeting, setEditingMeeting} = useLeaderStore(state => state);
  const [groupMeetings, setGroupMeetings] = useState({
    id: '',
    meeting_weeks: '',
    starting_time: '',
    meeting_day: '',
    meeting_place: '',
    meeting_time: '',
  });
  const [groupMeetingsChanges, setGroupMeetingsChanges] = useState({
    id: '',
    meeting_weeks: '',
    starting_time: '',
    meeting_day: '',
    meeting_place: '',
    meeting_time: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupIdParams = searchParams.get('group_id');
  const type = searchParams.get('type');
  const [isPromptEditing, setIsPromptEditing] = useState(false);
  const [promptMsg, setPromptMsg] = useState('');
  const [weeklyUpdate, setWeeklyUpdate] = useState(false);
  const weekDays = [
    {label: 'Monday', value: 'monday'},
    {label: 'Tuesday', value: 'tuesday'},
    {label: 'Wednesday', value: 'wednesday'},
    {label: 'Thursday', value: 'thursday'},
    {label: 'Friday', value: 'friday'},
    {label: 'Saturday', value: 'saturday'},
    {label: 'Sunday', value: 'sunday'}
  ];
  const allWeeks = [
    {label: '42', value: 42},
    {label: '43', value: 43},
    {label: '44', value: 44},
    {label: '45', value: 45},
    {label: '46', value: 46},
    {label: '47', value: 47},
    {label: '48', value: 48}
  ];

  const updateMeetingHandler = async () => {
    const data = {
      meeting_weeks: groupMeetings?.meeting_weeks,
      starting_time: moment(groupMeetings?.starting_time).format("YYYY-MM-DD"),
      meeting_day: groupMeetings?.meeting_day,
      meeting_place: groupMeetings?.meeting_place,
      meeting_time: groupMeetings?.meeting_time,
      group_id: groupIdParams
    };
        try {
      setButtonLoading(true);
      const res = await updateMeetingApi(groupMeetings.id, data);
      if (res?.data?.status === 200) {
        setMeetings({...data, id: meetings.id});
        navigate(`/leader/reading-plans?group_id=${groupIdParams}`);
        setEditingMeeting(null);
      }
      setIsPromptEditing(false);
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      setIsPromptEditing(false);
    }
  };

  const postUpdateBibleReadingPlan = async id => {
    const data = {
      id: editingMeeting?.id,
      meeting_day: groupMeetings?.meeting_day,
      meeting_place: groupMeetings?.meeting_place,
      meeting_time: new Date(moment(groupMeetings?.meeting_time)),
      starting_time: groupMeetings?.starting_time,
      meeting_weeks: groupMeetings?.meeting_weeks
    };
        try {
      const response = await postUpdateBibleReadingPlanApi(id, data);
      if (response?.data?.status == 200) {
        navigate(`/group/${group?.name}/${groupIdParams}`);
        setEditingMeeting(null);
      }
    } catch (error) {}
  };

  const handleNextPage = () => {
        if (editingMeeting) {
        setPromptMsg(`Please note that changing the meeting day will impact all future scheduled meetings, and any skip weeks will be removed if the changes are saved. Additionally, this change is irreversible once applied.`)
        setWeeklyUpdate(true)
        setIsPromptEditing(true);
    } else if (meetings.length === 0) {
      sendMeetingInviteHandler(groupMeetings);
    } else {
      if(checkObjectAnyKeyHasValue(groupMeetingsChanges)) {
        setPromptMsg('Any changes on the meeting, you will loose all your readings of this group.')
          setWeeklyUpdate(false)
        setIsPromptEditing(true);
      }else {
        navigate(`/leader/reading-plans?group_id=${groupIdParams}`);
      }
    }
  };

  const handleChangeInput = (value, name) => {
    setGroupMeetings(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
    setGroupMeetingsChanges(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const sendMeetingInviteHandler = async data => {
    const meetingData = {
      meeting_weeks: data?.meeting_weeks,
      starting_time: moment(data?.meeting_date).format('YYYY-MM-DD'),
      meeting_day: data?.meeting_day,
      meeting_place: data?.meeting_place,
      meeting_time: data?.meeting_time,
      group_id: groupIdParams
    };
    const isValid = allFieldsHaveValue(meetingData);
    if (!isValid) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    try {
      const res = await sendMeetingInvite(meetingData);
      if (res?.data?.status === 200) {
        if (res?.data?.status === 200) {
          setMeetings(res?.data?.data);
          navigate(`/leader/reading-plans?group_id=${groupIdParams}`);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (editingMeeting) {
      setGroupMeetings({
        id: editingMeeting?.id,
        meeting_weeks: editingMeeting?.meeting_weeks || 48,
        starting_time: editingMeeting?.meeting_date,
        meeting_day: editingMeeting?.meeting_day || 'monday',
        meeting_place: editingMeeting?.meeting_place,
        meeting_time: moment.utc(editingMeeting?.meeting_time).format('YYYY-MM-DD HH:mm:ss'),
        
      });
    } else {
      setGroupMeetings({
        id: meetings?.id,
        meeting_weeks: meetings?.meeting_weeks || 48,
        starting_time: meetings?.starting_time,
        meeting_day: meetings?.meeting_day || 'monday',
        meeting_place: meetings?.meeting_place,
        meeting_time: moment.utc(meetings?.meeting_time).local().format('YYYY-MM-DD HH:mm:ss'),
      });
    }
  }, [editingMeeting, meetings]);

  const isFieldDisabled = () => {
    if (type === 'weekly') {
      return true;
    }
    let isDisabled = false;
    if (group?.disciple || group?.group_meeting?.starting_time) {
      isDisabled = group?.disciple?.length > 0 || moment.utc(group?.group_meeting?.starting_time).isBefore() ? true : false;
    } else {
      isDisabled = false;
    }
    return isDisabled;
  };

  const disabledDates = date => {
    let dayOfWeeks = groupMeetings?.meeting_day;
    let weekNo;
    if (dayOfWeeks === 'monday') {
      weekNo = 1;
    }
    if (dayOfWeeks === 'tuesday') {
      weekNo = 2;
    }
    if (dayOfWeeks === 'wednesday') {
      weekNo = 3;
    }
    if (dayOfWeeks === 'thursday') {
      weekNo = 4;
    }
    if (dayOfWeeks === 'friday') {
      weekNo = 5;
    }
    if (dayOfWeeks === 'saturday') {
      weekNo = 6;
    }
    if (dayOfWeeks === 'sunday') {
      weekNo = 0;
    }
    return new Date(date).getDay() !== weekNo;
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box mt={1} px={3} overflow="auto">
        <HeaderContainer pt={1} mt={2}>
          <ArrowBackIcon
            style={{cursor: 'pointer', color: 'white'}}
            onClick={() => {
              navigate(-1);
              setEditingMeeting(null);
            }}
            color="white"
          />
        </HeaderContainer>
        <FormWrapper buttonInputs={[{text: 'Update', onClick: handleNextPage}]} headingText="The Meeting" loading={buttonLoading}>
          <SelectInput
            placeholder="Select a Day of the Week"
            label={'What Day Will You Meet?'}
            value={groupMeetings?.meeting_day}
            setValue={value => {
              handleChangeInput(value, 'meeting_day');
              if (!meetings?.meeting_place) {
                handleChangeInput(null, 'starting_time');
              }
            }}
            optionArray={weekDays}
          />
          <TimePickerComponent
            label="WHAT TIME WILL YOU MEET?"
            onChange={e => {
              setError(null);
              handleChangeInput(moment(e), 'meeting_time');
            }}
            value={groupMeetings?.meeting_time ? moment(groupMeetings?.meeting_time) : null}
          />
                      <Box mt={1.5}>
            <TextInput
              placeholder="Ex. My Living Room, Our Church"
              label="Where Will You Meet?"
              value={groupMeetings?.meeting_place}
              maxLength={CONSTANTS.MAX_GROUP_LENGTH}
              onChange={e => {
                setError(null);
                handleChangeInput(e.target.value, 'meeting_place');
              }}
              labelVariant="body2"
            />
          </Box>
          <Box mt={1.5}>
            <Typography textAlign="left">WHAT DAY WILL YOU START</Typography>
            <DatePicker
              open={dateOpen}
              setOpen={() => setDateOpen(true)}
              onClose={() => setDateOpen(false)}
              value={groupMeetings?.starting_time ? moment(groupMeetings?.starting_time, 'YYYY-MM-DD') : null}
              onChange={newValue => {
                setError(null);
                handleChangeInput(newValue, 'starting_time');
              }}
              minDate={moment(new Date()).subtract(1, 'year')}
              showDaysOutsideCurrentMonth
              shouldDisableDate={disabledDates}
              disabled={isFieldDisabled()}
            />
          </Box>
          <Box mt={1.5} sx={{textAlign: 'left'}}>
            <Typography variant="body2">HOW MANY WEEKS WILL YOU MEET?</Typography>
            <Box
              mt={1.5}
              display={'flex'}
              alignItems={'center'}
              sx={{
                '& >[class^=css-]': {
                  width: 'unset'
                }
              }}
            >
              <Box sx={{xs: {width: '25%'}, md: {width: '9%'}}}>
                <SelectInput
                  value={groupMeetings?.meeting_weeks === undefined ? 42 : groupMeetings?.meeting_weeks}
                  setValue={value => {
                    setGroupMeetings(prev => {
                      return {
                        ...prev,
                        meeting_weeks: value
                      };
                    });
                    setGroupMeetingsChanges(prev => {
                      return {
                        ...prev,
                        meeting_weeks: value
                      };
                    });
                  }}
                  optionArray={allWeeks}
                  disabled={isFieldDisabled()}
                />
              </Box>
              <Box ml={2} mb={1}>
                <Typography variant="body3" fontWeight="400" fontSize={14}>
                  48* weeks is recommended under most circumstances
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && typeof error === 'string' && error.trim().length > 0 && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
        {/* )} */}
      </Box>
      <BottomBar
        buttonInputs={[
          {text: 'Home', onClick: () => navigate('/leader')},
          {text: '', onClick: () => {}, isShow: false},
          {
            text: 'Reading',
            onClick: () => navigate('/leader/meetings'),
            isShow: false
          },
          {text: 'Settings', onClick: () => {}}
        ]}
      />

      <Modal
        open={isPromptEditing}
        setOpen={setIsPromptEditing}
        title="Change Meeting Day of Future Meetings"
        buttons={[
          {name: 'No', onClick: () => setIsPromptEditing(false)},
          {
            name: 'Yes',
            onClick: () => {
              if(!weeklyUpdate){
                              updateMeetingHandler(groupMeetings);
              }else{
                postUpdateBibleReadingPlan(searchParams.get('group_id'))
              }
             
            },
            loading: buttonLoading
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{promptMsg}</Typography>
      </Modal>
    </Box>
  );
};

export default StartMeeting;
