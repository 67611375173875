import React, {useEffect, useState} from 'react';
import UserContainer from '../components/common/UserContainer';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Avatar, Button, Grid, Typography, Box} from '@mui/material';
import {FILE_PATH_URL} from '../constants';
import {getGroupApi, getGroupDiscipleApi, markAttendenceOfGroupMeeting, getAttendenceOfGroupMeeting} from '../api';
import moment from 'moment';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../components/common/Loader';
import CheckBoxInput from '../components/common/CheckBoxInput';

const DiscipleItem = ({disciple, attendance, handleCheckboxChange}) => {
  return (
    <Box py={2} ml={2} display="flex" alignItems="center" sx={{cursor: 'pointer'}} justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <CheckBoxInput checked={attendance.includes(disciple?.disciple_id)} onChange={() => handleCheckboxChange(disciple?.disciple_id)} disabled={true} />
        <Avatar sx={{width: 56, height: 56, ml: 2}} alt={disciple?.first_name} src={disciple?.avatar} />
        <Box textAlign="left" ml={2}>
          <Typography variant="h5" fontWeight={600}>
            {disciple?.first_name + ' ' + disciple?.last_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Attendance = () => {
  const [groupData, setGroupData] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const getGroupDetailsHandler = async () => {
    try {
      const res = await getGroupDiscipleApi(params?.id);
      if (res?.data?.status === 200) {
        const activeDisciples = res?.data?.data.filter(disciple => disciple.user_status === 'ACTIVE');
        setDisciples(activeDisciples);
      }
    } catch (error) {
      // setError('Failed to fetch group disciples');
    }
  };

  const getGroupHandler = async () => {
    try {
      const res = await getGroupApi(params?.id);
      if (res?.data?.status === 200) {
        setGroupData(res?.data?.data);
      }
    } catch (error) {
      // setError('Failed to fetch group data');
    }
  };

  const handleCheckboxChange = discipleId => {
    setAttendance(prev => {
      if (prev.includes(discipleId)) {
        return prev.filter(id => id !== discipleId);
      } else {
        return [...prev, discipleId];
      }
    });
  };

  const handleSubmitAttendance = async () => {
    const data = {
      meeting_id: params?.meetingId,
      attendese: attendance
    };
    try {
      const res = await markAttendenceOfGroupMeeting(data);
      if (res?.data?.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      // setError('Failed to submit attendance');
    }
  };

  const getAttendance = async () => {
    try {
      const res = await getAttendenceOfGroupMeeting(params?.meetingId);
      if (res?.data?.status === 200) {
        
        const result = res.data.data.attendance;
        const attendanceArray = result
          .split(',')
          .map(Number)
          .filter(id => id !== 0);
        setAttendance(attendanceArray);
      }
    } catch (error) {
      // setError('Failed to submit attendance');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([getGroupHandler(), getGroupDetailsHandler(), getAttendance()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <UserContainer onClick={() => navigate('/create-group')} addText="Add Disciple" isShow2={false} headingText={groupData?.name ? `${groupData?.name}'s Group` : ''} subHeadingText={groupData?.group_meeting?.meeting_time ? `${moment(new Date(groupData?.group_meeting?.meeting_time)).format('h:mm A')} on ${groupData?.group_meeting?.meeting_day} at ${groupData?.group_meeting?.meeting_place}` : ''} filePath={`${FILE_PATH_URL}/${groupData?.avatar}`} isShow={false}>
        <HeaderContainer textAlign="left" m={2} variant="h5" fontWeight={600}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
          <Typography textAlign="left" ml={2} variant="h5" fontWeight={600}>
            Attendance
          </Typography>
        </HeaderContainer>
        {loading ? (
          <Loader />
        ) : disciples && disciples.length > 0 ? (
          <Box>
            {disciples.map(disciple => (
              <DiscipleItem key={disciple.disciple_id} disciple={disciple} attendance={attendance} handleCheckboxChange={handleCheckboxChange} />
            ))}
          </Box>
        ) : (
          <Box marginTop={10}>
            <Typography variant="h5" marginBottom={4}>
              Invite your first people!
            </Typography>
            <Typography variant="body1" color="white">
              Once a disciple is added manually or accepts an invitation, they will be added here.
            </Typography>
          </Box>
        )}
        <Grid item sx={{height: '50px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        {/* {disciples && disciples.length > 0 && (
          <Box m={2}>
            <Button sx={{fontWeight: 400}} onClick={handleSubmitAttendance} variant="contained" color="secondary">
              Submit Attendance
            </Button>
          </Box>
        )} */}
      </UserContainer>
    </>
  );
};

export default Attendance;
