import {Avatar, Box, Button, IconButton, Typography, Tooltip} from '@mui/material';
import UserContainer from '../components/common/UserContainer';
import {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {FILE_PATH_URL} from '../constants';
import {deleteDiscipleApi, getGroupApi, getGroupDiscipleApi, getMeetings} from '../api';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import moment from 'moment';
import TextInput from '../components/common/TextInput';
import theme from '../styles/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../components/common/Modal';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {sendInviteToDisciple} from '../api';
import useLeaderStore from '../store/leaderStore';
import {FILE_PATH_PROFILE_URL} from '../constants';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import AttendanceIcon from '@mui/icons-material/Person';
import Loader from '../components/common/Loader';

const DiscipleItem = ({disciple, onClick, deleteHandler, setInvitedDisciple}) => {
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const groupID = useParams().id;

  const sendInviteHandler = async () => {
    setLoading(true);
    let emails = [];
    if (disciple?.email) {
      emails.push(disciple?.email);
    }
    const data = {
      emails: [disciple?.email],
      group_id: groupID
    };
    try {
      const resp = await sendInviteToDisciple(data);
      if (resp?.data.status === 200) {
        setInvitedDisciple(prevInvitedDisciple => {
          return prevInvitedDisciple.map(d => (d.email === disciple.email ? {...d, invite_count: d.invite_count + 1} : d));
        });
      }
      setLoading(false);
      setResendModalOpen(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setResendModalOpen(false);
    setLoading(false); // Reset loading state when the modal closes
  };
  const isInvited = disciple?.user_status === 'INVITED';
  const isHide = !(disciple?.status === 'ARCHIVED' || disciple?.status === 'COMPLETED');

  return (
    <>
      <Modal
        open={resendModalOpen}
        setOpen={handleModalClose}
        title="Resend Invite"
        buttons={[
          {name: 'No', onClick: () => handleModalClose()},
          {
            name: 'Yes',
            onClick: () => sendInviteHandler(),
            loading: loading,
            disabled: loading
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>Are you sure you want to resend invite to disciple?</Typography>
      </Modal>
      <Box p={2} display="flex" alignItems="center" sx={{cursor: 'pointer'}} justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
          <Box onClick={() => (isHide ? onClick() : () => {})} display="flex" alignItems="center">
            <Avatar sx={{width: 56, height: 56}} alt={String(disciple?.first_name).toUpperCase()} src={disciple?.avatar ? `${FILE_PATH_PROFILE_URL}/${disciple?.avatar}` : ''} />
            <Box textAlign="left" ml={2}>
              <Typography variant="h5" fontWeight={600}>
                {disciple?.first_name + ' ' + disciple?.last_name}
              </Typography>
              {disciple?.title && (
                <Typography variant="p" color="white" fontSize={14}>
                  {disciple?.title?.replace(/,/g, ' • ')}
                </Typography>
              )}
              {isInvited && (
                <Typography variant="p" color="white" fontSize={14}>
                  Invite Count: {disciple?.invite_count || 1}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="end">
            {isInvited && (
              <Tooltip title="Resend Invite" placement="top">
                <IconButton
                  onClick={() => {
                    setResendModalOpen(true);
                  }}
                  disabled={loading}
                >
                  <AutorenewIcon
                    fontSize="22"
                    style={{
                      fontSize: '2rem',
                      color: theme.palette.error.main
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {isHide && (
              <Tooltip title="Delete" placement="top">
                <IconButton
                  onClick={() => {
                    deleteHandler(disciple?.id);
                  }}
                >
                  <DeleteIcon
                    fontSize="22"
                    style={{
                      fontSize: '2rem',
                      color: theme.palette.error.main
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Home = () => {
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedDisciples, setSortedDisciples] = useState([]);
  const [sortedActiveDisciples, setSortedActiveDisciples] = useState([]);
  const [sortedInvitedDisciples, setSortedInvitedDisciples] = useState([]);
  const [sortedOrder, setSortedOrder] = useState('desc');
  const [activeDisciple, setActiveDisciple] = useState([]);
  const [invitedDisciple, setInvitedDisciple] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingDiscipleId, setDeletingDiscipleId] = useState(null);
  const {setMeetings, setGroupsData, meetings, setEditingMeeting, setGroupDisciples} = useLeaderStore(state => state);
  const params = useParams();
  const navigate = useNavigate();
  const today = moment().startOf('day');

  const getGroupDetailsHandler = async () => {
    try {
      const res = await getGroupDiscipleApi(params?.id);
      if (res?.data?.status === 200) {
        setSortedDisciples(res?.data?.data);
      }
    } catch (error) {}
  };

  const getGroupByIdHandler = async () => {
    try {
      const res = await getGroupApi(params.id);
      if (res?.data?.status === 200) {
        setGroupData(res?.data?.data);
        setGroupsData(res?.data?.data);
      }
    } catch (error) {}
  };

  const groupMeetingsById = async () => {
    try {
      const res = await getMeetings(params.id);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
      }else{
        navigate('/leader')
        setMeetings([]);
      }
    } catch (error) {
    }
  };

  const handleSearchTextInput = e => {
    setActiveDisciple(sortedActiveDisciples?.filter(org => org?.first_name?.toLowerCase().includes(e.target.value.toLowerCase()) || org?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())));
    setInvitedDisciple(sortedInvitedDisciples?.filter(org => org?.first_name?.toLowerCase().includes(e.target.value.toLowerCase()) || org?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())));
  };

  const handleSort = () => {
    setSortedOrder(prevSortOrder => (prevSortOrder == 'desc' ? 'asc' : 'desc'));
    setSortedDisciples(prevSortedOrgs => [...prevSortedOrgs].sort((a, b) => (sortedOrder == 'asc' ? a.first_name.localeCompare(b.first_name) : b.first_name.localeCompare(a.first_name))));
  };

  const deleteDiscipleHandler = async id => {
    try {
      const res = await deleteDiscipleApi(id);
      if (res?.data?.status === 200) {
        setDeleteModalOpen(false);
        getGroupDetailsHandler();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getGroupDetails = getGroupDetailsHandler();
    const getGroupById = getGroupByIdHandler();
    const getMeetingById = groupMeetingsById();
    setLoading(true);
    Promise.all([getGroupDetails, getGroupById, getMeetingById])
      .then(results => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const invitedDis = sortedDisciples?.filter(dis => dis?.user_status === 'INVITED');

    const activeDis = sortedDisciples?.filter(dis => dis?.user_status === 'ACTIVE');

    setInvitedDisciple(invitedDis);
    setActiveDisciple(activeDis);
    setSortedActiveDisciples(activeDis);
    setSortedInvitedDisciples(invitedDis);
    setGroupDisciples(sortedDisciples);
  }, [sortedDisciples]);

  const displayText = moment(groupData?.group_meeting?.starting_time).format('DD-MM-YYYY') > moment(new Date(Date.now())).format('DD-MM-YYYY') ? 'Reading' : groupData?.outside_book_reading_type ? 'Reading' : 'Meeting';

  return (
    <>
      <Modal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title="Delete Disciple"
        buttons={[
          {name: 'Close', onClick: () => setDeleteModalOpen(false)},
          {
            name: 'Delete',
            onClick: () => deleteDiscipleHandler(deletingDiscipleId)
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>Are you sure you want to delete disciple?</Typography>
      </Modal>

      <UserContainer
        addText={'Disciple'}
        addText2={displayText}
        headingText={groupData?.name ? `${groupData?.name}'s Group` : ''}
        filePath={groupData?.avatar ? `${FILE_PATH_URL}/${groupData?.avatar}` : ''}
        isShow={meetings && groupData?.status !== 'COMPLETED' ? true : false}
        isShow2={groupData?.status !== 'COMPLETED'}
        isGroup={true}
        groupData={{
          ...groupData,
          group_meeting: meetings,
          disciple: sortedDisciples
        }}
        headerText={groupData?.organization || ''}
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" pt={1}>
          {loading ? (
            <Loader />
          ) : sortedDisciples && sortedDisciples?.length > 0 ? (
            <Box>
              <Box px={2} pb={1} borderBottom={2} borderColor="black">
                <Button sx={{fontWeight: 400}} onClick={() => navigate(`/group/send-email/${groupData?.name}/${groupData?.id}`)} variant="contained" color="secondary">
                  Send Email To Group
                </Button>
              </Box>
              {
                sortedDisciples.length > 2 && (
                  <Box display="flex" alignItems="center" gap={2} pt={2} px={2}>
                    <TextInput type="text" placeholder="Search..." onChange={handleSearchTextInput} />
                    <IconButton onClick={handleSort} sx={{marginBottom: '16px'}}>
                      <SortByAlphaIcon
                        fontSize="22"
                        style={{
                          fontSize: '2rem',
                          color: theme.palette.error.main
                        }}
                      />
                    </IconButton>
                  </Box>
                )
              }
              <Typography textAlign="left" m={2} variant="h5" fontWeight={600}>
                Active Disciples ({activeDisciple?.length})
              </Typography>
              {activeDisciple?.map((dis, i) => (
                <DiscipleItem
                  key={i}
                  disciple={dis}
                  onClick={() => navigate(`/assessment/${groupData?.level}/${groupData?.id}/${dis?.user_id}`)}
                  deleteHandler={() => {
                    setDeleteModalOpen(true);
                    setDeletingDiscipleId(dis?.disciple_id);
                  }}
                />
              ))}

              <Typography textAlign="left" m={2} variant="h5" fontWeight={600}>
                Invited Disciples ({invitedDisciple?.length})
              </Typography>
              {invitedDisciple?.map((dis, i) => (
                <>
                  <DiscipleItem
                    key={i}
                    disciple={dis}
                    onClick={() => {}}
                    deleteHandler={() => {
                      setDeleteModalOpen(true);
                      setDeletingDiscipleId(dis?.disciple_id);
                    }}
                    setInvitedDisciple={setInvitedDisciple}
                  />
                </>
              ))}
            </Box>
          ) : (
            <Box px={2} my={10}>
              <Typography variant="h5" marginBottom={4}>
                Invite your first people!
              </Typography>
              <Typography variant="p" color="white">
                Once a disciple is added manually or accepts an invitation, they will be added here
              </Typography>
            </Box>
          )}

             {groupData?.status !== 'COMPLETED' && (
            <Box my={3}>
              {meetings?.group_week_meetings?.map(data => {
                const meetingDate = moment(data?.meeting_date).startOf('day'); // Convert meeting date to start of day for comparison
                const isToday = meetingDate.isSame(today); // Compare dates

                return (
                  <Box key={data.id}>
                    {/* Ensure you have a unique key for each child in a list */}
                    <Typography variant="h5" fontWeight="bold" fontSize="large" display="flex" justifyContent="center" alignItems="center">
                      Reading for {meetingDate.format('MMMM DD')} Meeting{' '}
                      <IconButton
                        onClick={() => {
                          if (isToday) {
                            navigate(`/group/attendance/${groupData?.name}/${groupData?.id}/${data?.id}`);
                          } else {
                            setEditingMeeting({...data, meeting_weeks: meetings?.meeting_weeks, meeting_date: meetings?.starting_time});
                            navigate(`/leader/meetings?group_id=${params?.id}&type=weekly`);
                          }
                        }}
                      >
                        {isToday ? (
                          <AttendanceIcon sx={{color: 'white'}} fontSize="8px"/> // Show Attendance Icon if today
                        ) : (
                          <EditIcon sx={{color: 'white'}} fontSize="8px" /> // Otherwise show Edit Icon
                        )}
                      </IconButton>
                    </Typography>
                    <Typography>{data?.week_chapters}</Typography>
                    {/* <Typography>{data?.outside_reading}</Typography> */}
                    {data?.outside_reading?.split('|')?.map(book => {
                    return (
                      <Box mt={1} mx={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography marginRight={1}>{book}</Typography>
                      </Box>
                    );
                  })}
                  </Box>
                );
              })}


              {meetings?.outside_reading?.next_book && (
                <Box>
                  <Typography variant="h5" fontWeight="bold" fontSize="large" pt={1}>
                    It's Time to Order!
                  </Typography>
                  {meetings?.outside_reading?.next_book?.split('|')?.map(book => {
                    return (
                      <Box mt={1} mx={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography marginRight={1}>{book}</Typography>
                        <Link to="https://disciples.sustainable-discipleship.com" target="_blank">
                          <LaunchIcon sx={{color: 'white'}} />
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </UserContainer>
    </>
  );
};

export default Home;
