import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Grid, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import CheckBoxInput from '../../common/CheckBoxInput';
import DropdownWithScore from '../../common/DropdownScore';
import QuestionHeadings from './QuestionHeadings';

const PageThree = ({nextpage, user, page, totalPage}) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);
  const [stepAnswer, setStepAnswer] = useState({
    9: 0,
    10: 0
  });

  const answersData = {
    9: 'Eg. Nursery, teaching, etc',
    10: "Eg. They say they're called to Saudi Arabia",
    11: ['Step Back Doing', 'Step Up Doing']
  };

  const fetchQuestions = async () => {
    try {
      setLoading(false);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_2, user?.id, user?.group);
      if (response?.data?.status === 200) {
        setQuestions(response?.data?.data);
        const initialAnswers = response?.data?.data?.reduce((acc, curr) => {
          const questionId = curr?.id;
          if (questionId === 9) {
            const score = String(curr?.answer)?.split('|')[1] ? String(curr?.answer)?.split('|')[1] : 0;
            setStepAnswer(prev => {
              return {
                ...prev,
                [questionId]: score
              };
            });
          }
          if (questionId === 10) {
            const score = String(curr?.answer)?.split('|')[1] ? String(curr?.answer)?.split('|')[1] : 0;
            setStepAnswer(prev => {
              return {
                ...prev,
                [questionId]: score
              };
            });
          }
          if (questionId === 9 || questionId === 10) {
            acc[curr.id] = String(curr?.answer).split('|')[0] || '';
          } else {
            acc[curr.id] = curr?.answer || '';
          }
          return acc;
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    if (questionId === 11) {
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: prevAnswers[questionId] === value ? '' : value
      }));
    } else if (questionId === 9) {
      const selectedOption = CONSTANTS.DOING_ASSESSMENT.find(option => option.value === value);
      const score = selectedOption ? selectedOption.score : 0;
      setStepAnswer(prev => {
        return {
          ...prev,
          [questionId]: score
        };
      });
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: `${value}|${score}`
      }));
    }
    if (questionId === 10) {
      const selectedOption = CONSTANTS.BE_ASSESSMENT.find(option => option.value === value);
      const score = selectedOption ? selectedOption.score : 0;
      setStepAnswer(prev => {
        return {
          ...prev,
          [questionId]: score
        };
      });
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: `${value}|${score}`
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = Object.entries(answers).every(([questionId, answer]) => {
      if (questionId === '11') return true;
      return answer.trim() !== '';
    });
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(CONSTANTS.MESSAGES.SOME_FIELDS_REQUIRED);
    }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: Object.entries(answers).map(([questionId, answer]) => ({
        question_id: questionId,
        answer: questionId == 9 || questionId == 10 ? `${answer.trim()}|${stepAnswer[questionId]}` : answer.trim()
      }))
    };
    try {
      setLoading(true);

      const response = await postAssessmentsQuestions(data);

      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: 'Continue',
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`ASSESSMENT ${page} OF ${totalPage}`} heading2="INITIAL PATHWAY ASSESSMENT" heading3="BY WEEK SIX" />
          <Box pt={4} mb={1}>
            {questions.map((ques, index) => {
              if (ques?.id === 11) {
                return (
                  <Box key={index}>
                    <Typography variant="body2" textAlign="left">
                      {ques?.question}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <CheckBoxInput onChange={() => handleAnswerChange(ques?.id, answersData[ques?.id][0])} checked={answers[ques?.id] === answersData[ques?.id][0]} label={answersData[ques?.id][0]} />
                      <CheckBoxInput onChange={() => handleAnswerChange(ques?.id, answersData[ques?.id][1])} checked={answers[ques?.id] === answersData[ques?.id][1]} label={answersData[ques?.id][1]} />
                    </Box>
                  </Box>
                );
              } else if (ques?.id === 10) {
                return (
                  <Box key={index}>
                    <DropdownWithScore questionId={ques.id} label={ques.question} options={CONSTANTS.BE_ASSESSMENT} answers={answers} handleAnswerChange={handleAnswerChange} setStepAnswer={setStepAnswer} stepAnswer={stepAnswer} />
                  </Box>
                );
              } else {
                return (
                  <Box key={index}>
                    <DropdownWithScore questionId={ques.id} label={ques.question} options={CONSTANTS.DOING_ASSESSMENT} answers={answers} handleAnswerChange={handleAnswerChange} setStepAnswer={setStepAnswer} stepAnswer={stepAnswer} />
                  </Box>
                );
              }
            })}
          </Box>
          <Grid item xs={12} mt={2} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageThree;
