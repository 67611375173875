import React, {useEffect, useState} from 'react';
import {Box, ListItem, ListItemText, Modal, Typography, useMediaQuery} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {addUserRoleApi, getAdminLeadersGroupApi, getAdminOrgDisciplesApi, getLeaderDiscipleDetailsApi, inviteUser} from '../../api';
import useAuthStore from '../../store/authStore';
import AdminStructure from './common/AdminStructure';
import RadioButtonGroup from '../../components/common/RadioButtonGroup';
import {CONSTANTS} from '../../constants';
import {validateEmail} from '../../utils';
import useAdminStore from '../../store/adminStore';
import CustomButton from '../../components/common/Button';
import InviteUser from '../../components/modules/InviteUser';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';

const mdStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  minHeight: 500,
  bgcolor: '#9e0001',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

const smallerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  minHeight: 250,
  bgcolor: '#9e0001',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
  outline: 'none'
};

function Leaders() {
  const {user} = useAuthStore(state => state);
  const {organizationSelected} = useAdminStore(state => state);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [adminLeaders, setAdminLeaders] = useState([]);
  const [searchLeaders, setSearchLeaders] = useState([]);
  const [selectedModalOption, setSelectedModalOption] = useState('Invite Leader');
  const [formDataList, setFormDataList] = useState([]);
  const [adminData, setAdminData] = useState({
    name: '',
    email: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [discipleData, setDiscipleData] = useState([]);
  const [nominatedDisciple, setNominatedDisciple] = useState([]);
  const [loader, setLoader] = useState(false);
  const [leaderDetails, setLeaderDetails] = useState({
    groups_led: 0,
    total_groups_member: 0,
    disciples_made: 0,
    disciples_dropped: 0
  });

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleLeaderClick = async leader => {
    setLoader(true);
    setSelectedLeader(leader);
    const resp = await getLeaderDiscipleDetailsApi(leader?.id, 'leader', organizationSelected);
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data);
      setLoader(false);
    }
    setLoader(false);
  };

  const getLeaders = async () => {
    try {
      setSelectedLeader(null);
      setLoadingPage(true);
      const resp = await getAdminLeadersGroupApi(organizationSelected);

      if (resp?.data?.status == 200) {
        setAdminLeaders(resp?.data?.data);
        setSearchLeaders(resp?.data?.data);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const getLeaderGroups = async leader => {
    setLoader(true);
    try {
      const resp = await getAdminLeadersGroupApi(leader?.id, 'details', organizationSelected);
      setLeaderDetails(resp?.data?.data);
      setLoader(false);
    } catch (error) {}
    setLoader(false);
  };

  const inviteLeader = async (adminDetails, type) => {
    try {
      setLoading(true);
      const nameParts = adminDetails.name?.split(' ');
      const first_name = nameParts[0];
      const last_name = nameParts[1] || '';
      const data = {
        first_name,
        last_name,
        email: adminDetails.email,
        organization_ids: [organizationSelected]
      };
      const response = await inviteUser(data);
      if (response?.data?.status === 200) {
        const userId = response?.data?.data?.id;
        if (type === 'continue') {
          setFormDataList([]);
          handleClose();
          getLeaders();
        } else {
          setFormDataList(() => [...formDataList, {...adminData, user_id: userId}]);
        }
        setAdminData({
          name: '',
          email: ''
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFormDataList([]);
    }
  };

  const onAddLeader = async () => {
    if (!adminData.email && !adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (!adminData.email && adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (adminData.email && !adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (!validateEmail(adminData.email)) {
      return;
    }
    if (adminData.name && adminData.email) {
      inviteLeader(adminData, 'add');
    }
  };

  const continueHandler = () => {
    if (!adminData.email && !adminData.name && formDataList.length === 0) {
      setError(CONSTANTS.MESSAGES.ADD_ATLEAST_LEADER);
    } else if (!adminData.email && adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    } else if (adminData.email && !adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    } else {
      if (validateEmail(adminData.email) && adminData.name.trim()) {
        inviteLeader(adminData, 'continue');
      } else {
        if (formDataList.length > 0) {
          setOpen(false);
          setFormDataList([]);
          setAdminData({name: '', email: ''});
        }
      }
    }
  };

  const getNominatedDisciples = async () => {
    try {
      setNominatedDisciple([]);
      const res = await getAdminOrgDisciplesApi(organizationSelected, 'disciple', true);
      if (res?.data?.status === 200) {
        setNominatedDisciple(res?.data?.data);
      }
    } catch (error) {}
  };

  const handlePromoteDisciple = async id => {
    setLoadingStates(prevState => ({ ...prevState, [id]: true }));
    const permission = CONSTANTS.USER_ROLES['LEADER'];
    try {
      const res = await addUserRoleApi({user_id: id, permission, orgId: organizationSelected});
      setLoading(false);
      if (res.data.status === 200) {
        getLeaders();
        setOpen(false);
      }
    } catch (error) {
    }
    setLoadingStates(prevState => ({ ...prevState, [id]: false }));
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminLeaders(searchLeaders);
    } else {
      const filteredLeaders = searchLeaders.filter(leader => leader?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminLeaders(filteredLeaders);
    }
  };

  useEffect(() => {
    getLeaders();
  }, [organizationSelected]);

  useEffect(() => {
    if (selectedModalOption === 'Promote Nominated Disciples') {
      getNominatedDisciples();
    }
  }, [selectedModalOption]);

  // Calculate the counts of each status leader
  const activeLeadersCount = adminLeaders ? adminLeaders?.filter(leader => leader.status === 'ACTIVE')?.length : 0;
  const invitedLeadersCount = adminLeaders ? adminLeaders?.filter(leader => ['COMPLETED', `ARCHIEVED`, `INVITED`].includes(leader.status))?.length : 0;

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden',
            overflowX: 'hidden'
          }}
        >
          <AdminStructure isSearch={true} searchHandler={searchHandler} handleAddClick={() => {handleOpen(),setSelectedModalOption('Invite Leader')}} heading="Leaders" isAddingUser={true}>
            {loadingPage ? (
              <Loader color="#9e0001" />
            ) : adminLeaders.length === 0 ? (
              <NoDataFoundMessage mt={5} message="There are no leaders in this organization" />
            ) : (
              <Box
                display={'flex'}
                // justifyContent={"space-between"}
                px={2}
                gap={2}
                sx={{
                  height: {
                    md: '85vh',
                    xs: 'auto',
                    sm: 'auto'
                  },
                  flexDirection: {
                    md: 'row',
                    sm: 'column',
                    xs: 'column'
                  },
                  justifyContent: {
                    md: 'space-between'
                  }
                }}
              >
                <Box width="100%" display={'flex'} flexDirection={'column'} gap={2}>
                  <ItemList
                    items={adminLeaders.filter(leader => ['ACTIVE'].includes(leader.status))}
                    handleClick={e => {
                      handleLeaderClick(e);
                      getLeaderGroups(e);
                    }}
                    selectedItem={selectedLeader}
                    height={'50%'}
                    // deleteIcon="true"
                    reloadData={getLeaders}
                    title={`Active Leaders (${activeLeadersCount})`}
                  />
                  <ItemList
                    items={adminLeaders.filter(leader => ['COMPLETED', `ARCHIVED`, `INVITED`].includes(leader.status))}
                    handleClick={e => {
                      handleLeaderClick(e);
                      getLeaderGroups(e);
                    }}
                    selectedItem={selectedLeader}
                    height={'50%'}
                    // deleteIcon="true"
                    reloadData={getLeaders}
                    title={`Invited Leaders (${invitedLeadersCount})`}
                  />
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    justifyContent: {
                      md: 'space-between'
                    }
                    // paddingX: {
                    //   md: 2,
                    // },
                  }}
                  // px={2}
                >
                  {selectedLeader && (
                    <ItemDetails
                      title={selectedLeader.name}
                      selectedLeader={selectedLeader}
                      details={[
                        // {
                        //   name: "Year Completed:",
                        //   detail: "",
                        // },
                        {
                          name: 'Number of Groups Led:',
                          detail: leaderDetails?.groups_led
                        },
                        {
                          name: 'Total Group Members:',
                          detail: leaderDetails?.total_groups_member
                        },
                        {
                          name: 'Disciples Made:',
                          detail: leaderDetails?.disciples_made
                        },
                        {
                          name: 'Disciples Dropped:',
                          detail: leaderDetails?.disciples_dropped
                        }
                      ]}
                      height="42%"
                      loader={loader}
                    />
                  )}
                  {selectedLeader && <ItemDetails title={`Disciples led by ${selectedLeader.name}`} selectedLeader={selectedLeader} details={discipleData} height="42%" loader={loader} />}
                </Box>
              </Box>
            )}
          </AdminStructure>
        </div>
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={isSmallScreen ? smallerStyle : mdStyle}>
          <RadioButtonGroup
            options={['Invite Leader', 'Promote Nominated Disciples']}
            selectedOption={selectedModalOption}
            onChange={value => {
              setSelectedModalOption(value);
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              '& .MuiFormControlLabel-root': {width: '48%'}
            }}
          />
          <Box mt={4}>
            {selectedModalOption === 'Invite Leader' ? (
              <InviteUser continueHandler={continueHandler} onSubmit={onAddLeader} formDataList={formDataList} setFormDataList={setFormDataList} setData={setAdminData} data={adminData} loading={loading} setError={setError} error={error} heading={`Invite leader(s) for organization?`} buttonTextOne="Add Another Invitation" buttonTextTwo="Send Invitation(s)" />
            ) : nominatedDisciple.length > 0 ? (
              nominatedDisciple?.map(dis => {
                return (
                  <ListItem
                    key={dis?.id}
                    sx={{
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      borderRadius: '12px',
                      marginBottom: '5px'
                    }}
                  >
                    <ListItemText
                      primary={dis?.name}
                      primaryTypographyProps={{
                        sx: {
                          color: '#FFF'
                        }
                      }}
                    />
                    <Box width="30%">
                      <CustomButton loading={loadingStates[dis?.id]} text="Promote" onClick={() => handlePromoteDisciple(dis?.id)} disablePadding={true} />
                    </Box>
                  </ListItem>
                );
              })
            ) : (
              // add loader here since this box flickers for a second
              <Box>
                <Typography>No Disciple for the promotion of disciples</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Leaders;
